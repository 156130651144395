<div class="sticky-header header-section ">
    <div class="header-left">
        <!--toggle button start-->
        <div class="bar" (click)="toggleDrawer()">
            <button ><i class="fa fa-bars"></i></button>
            </div>
        <div class="logo">
            <a href="/"><img src="assets/images/logoWhite.png" class="img logo-img"></a>
        </div>
        <div class="clearfix"> </div>
    </div>
    <div class="header-right">
        <div class="profile_details_left">
            <!--notifications of menu start -->
            <ul class="nofitications-dropdown">
                <li class="dropdown head-dpdn">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
                            class="fa fa-envelope"></i><span class="badge">0</span></a>
                    <ul class="dropdown-menu">
                        <li>
                            <div class="notification_header">
                                <h3>You have 0 new messages</h3>
                            </div>
                        </li>
                        <!-- <li><a href="#">
                                <div class="user_img"><img src="assets/images/1.png" alt=""></div>
                                <div class="notification_desc">
                                    <p>Lorem ipsum dolor amet</p>
                                    <p><span>1 hour ago</span></p>
                                </div>
                                <div class="clearfix"></div>
                            </a></li> -->
                        <!-- </li> -->
                    </ul>
                </li>
                <li class="dropdown head-dpdn">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
                            class="fa fa-bell"></i><span class="badge blue">1</span></a>
                    <ul class="dropdown-menu">
                        <li>
                            <div class="notification_header">
                                <h3>You have 1 new notification</h3>
                            </div>
                        </li>
                        <li><a href="#">
                                <div class="clearfix"></div>
                            </a></li>
                        <li>
                            <div class="notification_bottom">
                                <a href="#">See all notifications</a>
                            </div>
                        </li>
                    </ul>
                </li>

            </ul>
            <div class="clearfix"> </div>
        </div>
        <!--notification menu end -->
        <div class="profile_details">
            <ul>
                <li class="dropdown profile_details_drop">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <div class="profile_img">
                            <span class="prfil-img"><img src="{{authenticatedUser.dp!=null?'https://lenos.s3.amazonaws.com/pictures/'+authenticatedUser.dp:'assets/images/avatar.png'}}" class="avatar" alt=""> </span>
                            <div class="user-name" *ngIf="isAuthenticated">
                                <p>{{authenticatedUser.name| titlecase | slice:0:13 }}..</p>
                                <span>{{authenticatedUser.email}}</span>
                            </div>
                            <div class="user-name" *ngIf="!isAuthenticated">
                                <p>Untitled User</p>
                                <span>Unknown</span>
                            </div>
                            <i class="fa fa-angle-down lnr"></i>
                            <i class="fa fa-angle-up lnr"></i>
                            <div class="clearfix"></div>
                        </div>
                    </a>
                    <ul class="dropdown-menu drp-mnu">
                        <li> <a (click)="logout();" ><i class="fa fa-sign-out"></i> Logout</a> </li>
                        <li> <a [routerLink]="['/profile']"><i class="fa fa-user"></i> My Profile</a> </li>
                        <li *ngIf="authenticatedUser.accountType!='ADMIN'"> <a [routerLink]="['/shop']"><i class="fa fa-certificate"></i> Manage Shop</a> </li>
                        <li *ngIf="authenticatedUser.accountType=='ADMIN'"> <a (click)="clearCatch();"><i class="fa fa-times"></i> Clear Caches</a> </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="clearfix"> </div>
    </div>
    <div class="clearfix"> </div>
</div>
<!-- //header-ends -->

<h1 mat-dialog-title>{{title}}
    <span class="material-icons close-icon" (click)="closeDialog()">
        cancel_presentation
    </span>
</h1>
<div mat-dialog-content>
    <form name="form" enctype="multipart/form-data">
        <div class="row">
            <div class="sign-u col-md-12">
                <div class="sign-up1">
                    <h4> Name<strong class="star">*</strong> :</h4>
                </div>
                <div class="sign-up2">
                    <input type="text" class="tf-lenos" name="name" required="true" [value]="existingSubCategory!.name"
                        (keyup)="form.validate($event)">
                </div>
                <div class="clearfix"> </div>
            </div>
        </div>
    </form>
</div>
<div class="group-button dialog-buttons" mat-dialog-actions>
        <div class="sub_home text-right">
            <button type="button" mat-button (click)="this.addCategory()" class="btn btn-lenos btn-round">Save</button>
            <div class="clearfix"> </div>
        </div>
</div>
<app-sidebar></app-sidebar>
<app-header></app-header>
<div class="main-content">
    <div id="page-wrapper">
        <div class="main-page">
            <div class="login-top left">
                <h3>Locations »</h3>
            </div>
            <div class="tables">
                <div class="bs-example widget-shadow" data-example-id="hoverable-table">
                    <ng-container *ngIf="isLoading">
                        <app-loader></app-loader>
                    </ng-container>
                    <ng-container *ngIf="locations!=null">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Code</th>
                                    <th>Description</th>
                                    <th>Delivery Fee</th>
                                    <th>Created Date</th>
                                    <th *ngIf="authenticatedUser.accountType==appAccountType.ADMIN">#</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let location of locations">
                                    <th scope="row">{{location!.id}}</th>
                                    <td>{{location!.code}}</td>
                                    <td>{{location!.description}}</td>
                                    <td>{{location!.deliveryFee}}</td>
                                    <td>{{location!.createdDate}}</td>
                                    <td *ngIf="authenticatedUser.accountType==appAccountType.ADMIN">
                                        <div class="controls row">
                                            <div class="col-md-6">
                                                <button type="button" class="btn btn-dynamic btn-round" (click)="deleteLocation(location)">
                                                    <i class="fa fa-times" style="font-size: 20px;"></i>
                                                </button>
                                            </div>
                                            <div class="col-md-6">
                                                <button type="button" class="btn btn-dynamic btn-round" (click)="editLocation(location)">
                                                    <i class="fa fa-pencil" style="font-size: 20px;"></i>
                                                </button>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                    <ng-container *ngIf="locations==null !isLoading">
                        <div class="errorWrapper">
                            No Location Available
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
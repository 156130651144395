<app-sidebar></app-sidebar>
<app-header></app-header>
<div class="main-content">
  <div id="page-wrapper">
    <div class="main-page signup-page input-container">
      <form name="form" enctype="multipart/form-data">
        <div class="login-top">
          <h3>Upload new Product »</h3>
        </div>
        <div class="sign-up-row widget-shadow">
          <h5>Basic Information :</h5>
          <div class="row">
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Title<strong class="star">*</strong> :</h4>
              </div>
              <div class="sign-up2">
                <input type="text" class="tf-lenos" name="name" [value]="this.product!=null?this.product.name:''"
                  required="true" (keyup)="form.validate($event)">
              </div>
              <div class="clearfix"> </div>
            </div>
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Brand :</h4>
              </div>
              <div class="sign-up2">
                <input type="text" class="tf-lenos" name="brand" [value]="this.product!=null?this.product.brand:''"
                  (keyup)="form.validate($event)">
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>
          <div class="row">
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Category <strong class="star">*</strong> :</h4>
              </div>
              <div class="sign-up2">
                <select class="tf-lenos" name="categoryId" required="true"
                  (change)="form.validate($event);onCategorySelected($event);">
                  <option readonly="" selected="" disabled="" value="">Select Category</option>
                  <option *ngIf="this.product!=null" [value]="this.product.categoryId" selected>
                    {{this.product.categoryName}}</option>
                  <option *ngFor="let categoryData of categories" [value]="categoryData!.category!.id">
                    {{categoryData!.category.name}}</option>
                </select>
              </div>
              <div class="clearfix"> </div>
            </div>
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Sub Category<strong class="star">*</strong> :</h4>
              </div>
              <div class="sign-up2">
                <select class="tf-lenos" name="subCategoryId" required="true" (change)="form.validate($event)">
                  <option readonly="" selected="" disabled="" value="">Select SubCategory</option>
                  <option *ngIf="this.product!=null" [value]="this.product.subCategoryId" selected>
                    {{this.product.subCategoryName}}</option>
                  <option *ngFor="let category of subCategories" [value]="category!.id">
                    {{category.name}}</option>
                </select>
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>

          <div class="row">
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Unit Size :</h4>
              </div>
              <div class="sign-up2">
                <select class="tf-lenos" name="size" (change)="form.validate($event)">
                  <option readonly="" selected="" disabled="" value="">Select a Unit</option>
                  <option *ngIf="this.product!=null" [value]="this.product.size" selected>{{this.product.size}}</option>
                  <option value="piece">Piece</option>
                  <option value="set">Set</option>
                  <option value="yard">Yard</option>
                  <option value="inch">Inch</option>
                  <option value="packet">Packet</option>
                  <option value="dozen">Dozen</option>
                  <option value="g">Gram</option>
                  <option value="kg">Kilo Gram</option>
                  <option value="tonne">Tonne</option>
                  <option value="mt">Mega Tonne</option>
                  <option value="gt">Giga Tonne</option>

                </select>
              </div>
              <div class="clearfix"> </div>
            </div>
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Available Colors:</h4>
              </div>
              <div class="sign-up2">
                <input type="text" class="tf-lenos" name="color" placeholder="e.g Red, Blue, Green" [value]="this.product!=null?this.product.color:''" (change)="form.validate($event)"/>
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>

          <div class="row">
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Delivery Time <strong class="star">*</strong> :</h4>
              </div>
              <div class="sign-up2">
                <select class="tf-lenos" name="deliveryDays" required="true" (change)="form.validate($event)">
                  <option readonly="" selected="" disabled="" value="">Select Delivery Time </option>
                  <option *ngIf="this.product!=null" [value]="this.product.deliveryDays" selected>
                    {{this.product.deliveryDays}}</option>

                  <option value="1">1 Day</option>
                  <option value="2">2 Days</option>
                  <option value="3">3 Days</option>
                  <option value="4">4 Days</option>
                  <option value="5">5 Days</option>
                  <option value="6">6 Days</option>
                  <option value="8">8 Days</option>
                  <option value="12">12 Days</option>
                  <option value="15">15 Days</option>
                  <option value="18">18 Days</option>
                  <option value="20">20 Days</option>
                  <option value="25">25 Days</option>
                  <option value="30">30 Days</option>
                  <option value="35">35 Days</option>
                  <option value="40">40 Days</option>
                  <option value="50">50 Days</option>
                  <option value="60">60 Days</option>
                </select>
              </div>
              <div class="clearfix"> </div>
            </div>
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Maximum Quantity<strong class="star">*</strong> :</h4>
              </div>
              <div class="sign-up2">
                <input type="number" class="tf-lenos" name="stock" [value]="this.product!=null?this.product.stock:''"
                  required="true" (keyup)="form.validate($event)">
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>

          <div class="row">
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Price <strong class="star">*</strong> :</h4>
              </div>
              <div class="sign-up2">
                <input type="number" class="tf-lenos" [value]="this.product!=null?this.product.price:''" name="price"
                  required="true" (keyup)="form.validate($event)">
              </div>
              <div class="clearfix"> </div>
            </div>
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Product Location <strong class="star">*</strong> :</h4>
              </div>
              <div class="sign-up2">
                <select class="tf-lenos" name="location" required="true" (change)="form.validate($event)">
                  <option readonly="" selected="" disabled="" value="">Select Current Product Location
                  </option>
                  <option *ngIf="this.product!=null" [value]="this.product.location" selected>{{this.product.location}}
                  </option>

                  <option *ngFor="let location of locations" value="{{location.description}}">
                    {{location.description}}</option>
                </select>
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>

          <div class="row">
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Product Source:</h4>
              </div>
              <div class="sign-up2">
                <input type="text" class="tf-lenos" [value]="this.product!=null?this.product.source:''" name="source"
                  (keyup)="form.validate($event)">
              </div>
              <div class="clearfix"> </div>
            </div>
            <div class="sign-u col-md-6">
              <div class="sign-up1">
                <h4>Product Sizes:</h4>
              </div>
              <div class="sign-up2">
                <input type="text" class="tf-lenos" placeholder="e.g XL, XXL, SM" [value]="this.product!=null?this.product.sizes:''" name="sizes"
                  (keyup)="form.validate($event)">
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>

          <div class="row">
            <div class="sign-u col-md-12">
              <div class="sign-up1">
                <h4>Feature :</h4>
              </div>
              <div class="sign-up2 textarea-large">
                <textarea class="tf-lenos" nam="features" name="features"
                  [value]="this.product!=null?this.product.features:''" (keyup)="form.validate($event)"></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="sign-u col-md-12">
              <div class="sign-up1">
                <h4>Description :</h4>
              </div>
              <div class="sign-up2 textarea-large">
                <textarea class="tf-lenos" nam="description" [value]="this.product!=null?this.product.description:''"
                  name="description" (keyup)="form.validate($event)"></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="sign-u col-md-12">
              <div class="sign-up1">
                <h4>Full Description :</h4>
              </div>
              <div class="sign-up2 textarea-large">
                <textarea class="tf-lenos" nam="fullDescription"
                  [value]="this.product!=null?this.product.fullDescription :''" name="fullDescription"
                  (keyup)="form.validate($event)"></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="sign-u col-md-12">
              <div class="sign-up1">
                <h4>Thumbnail <strong class="star" *ngIf="!editing">*</strong> :</h4>
              </div>
              <div class="sign-up2 textarea-large">
                <input type="file" class="tf-lenos" accept="image/x-png,image/gif,image/jpeg" name="thumbnail"
                  required="true" (change)="form.validate($event)">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="sign-u col-md-12">
              <div class="sign-up1">
                <h4>Product Images <strong class="star" *ngIf="!editing">*</strong> :</h4>
              </div>
              <div class="sign-up2 textarea-large">
                <input type="file" class="tf-lenos" accept="image/x-png,image/gif,image/jpeg" name="images[]"
                  multiple="true" required="true" (change)="form.validate($event)">
              </div>
            </div>
          </div>
          <br /><br />
          <div class="mt-5">
            <div class="clearfix"> </div>
            <div class="sub_home text-right">
              <button type="button" class="btn btn-lenos" (click)="uploadProduct();">{{editing?'Save Changes':'Upload
                Product'}}</button>
              <div class="clearfix"> </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-footer></app-footer>

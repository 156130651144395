<app-top></app-top>
<div class="main-page login-page input-container">
    <form name="form">
        <h3 class="title1">SignIn</h3>
        <div class="widget-shadow">
            <div class="login-top">
                <h4>Welcome back to Lenos Business Panel! <br> Not a Member? <a [routerLink]="['/signup']"> Sign Up »</a>
                </h4>
            </div>
            <div class="login-body">
                <div class="input">
                    <input type="text" class="user tf-lenos" name="username" placeholder="Business Email or Mobile"
                        required="true" (keyup)="form.validate($event)">
                </div>
                <div class="input">
                    <input type="password" class="lock tf-lenos" name="password" placeholder="password" required="true"
                        (keyup)="form.validate($event)">
                </div>
                <button type="button" class="btn btn-lenos" (click)="signIn()">Sign In</button>
                <div class="forgot-grid">
                    <label class="checkbox"><input type="checkbox" name="rememberMe" checked=""><i></i>Remember
                        me</label>
                    <div class="clearfix"> </div>
                </div>

            </div>
        </div>
    </form>
</div>
<app-footer></app-footer>

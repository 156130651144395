<app-sidebar></app-sidebar>
<app-header></app-header>
<div class="main-content">
    <div id="page-wrapper">
        <div class="main-page">
            <div class="login-top left">
                <h3>All Buyers »</h3>
            </div>
            <div class="tables">
                <div class="bs-example widget-shadow" data-example-id="hoverable-table">
                    <ng-container *ngIf="isLoading">
                        <app-loader></app-loader>
                    </ng-container>
                    <ng-container *ngIf="users!=null">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Created Date</th>
                                    <th>#</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let user of users">
                                    <th scope="row">1</th>
                                    <td>{{user!.name}}</td>
                                    <td>{{user!.mobile}}</td>
                                    <td>{{user!.email}}</td>
                                    <td>{{user!.createdDate}}</td>
                                    <td>
                                        <button type="button" class="btn btn-lenos btn-dynamic btn-round"
                                            (click)="deleteUser(user)">
                                            <i class="fa fa-times" style="font-size: 20px;"></i>
                                        </button>
                                        &nbsp;
                                        <label class="switch" *ngIf="user!.status=='AC'">
                                            <input type="checkbox" checked="true" (change)="activate($event,user)">
                                            <span class="slider round"></span>
                                        </label>
                                        <label class="switch" *ngIf="user!.status!='AC'">
                                            <input type="checkbox" (change)="activate($event,user)">
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <app-pagination [change]="changePage" [self]="this"
                            [currentPage]="this.currentPage" [totalPages]="this.totalPages">
                        </app-pagination>
                    </ng-container>
                    <ng-container *ngIf="users==null && !isLoading">
                        <div class="errorWrapper">
                            No Buyer Available
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>

<app-sidebar></app-sidebar>
<app-header></app-header>
<div class="main-content">
    <div id="page-wrapper">
        <div class="main-page">
            <div class="login-top left">
                <h3>Product Reviews »</h3>
            </div>
            <div class="tables">
                <div class="bs-example widget-shadow" data-example-id="hoverable-table">
                    <div class="inbox-page">
                        <ng-container *ngIf="isLoading">
                            <app-loader></app-loader>
                        </ng-container>
                        <ng-container *ngIf="reviews!=null  && !isLoading">
                            <div *ngFor="let reviewData of reviews" class="inbox-row" role="tablist"
                                aria-multiselectable="true">
                                <div class="mail"><img src="assets/images/avatar.png" class="img avatar" alt="" /></div>
                                <div class="user">
                                    <h6>{{reviewData!.user!.name}}</h6>
                                </div>
                                <div class="message">
                                    <a role="button" data-toggle="collapse" href="#collapse_{{reviewData!.review!.id}}"
                                        aria-expanded="true" [attr.aria-controls]="'collapse_'+reviewData!.review!.id">
                                        <div class="mail">
                                            <p>{{reviewData!.review!.review | slice:0:60}} <a href="#" class="link">Read
                                                    More</a></p>
                                        </div>
                                    </a>
                                </div>
                                <div class="mail-right">
                                    <div class="dropdown">
                                        <a href="#" data-toggle="dropdown" aria-expanded="false">
                                            <p><i class="fa fa-ellipsis-v mail-icon"></i></p>
                                        </a>
                                        <ul class="dropdown-menu float-right">
                                            <li>
                                                <a role="button" data-toggle="collapse"
                                                    href="#collapse_{{reviewData!.review!.id}}" aria-expanded="true"
                                                    [attr.aria-controls]="'collapse_'+reviewData!.review!.id">
                                                    <i class="fa fa-reply mail-icon"></i>
                                                    Reply
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" class="font-red" title=""
                                                    (click)="deleteReview(reviewData!.review)">
                                                    <i class="fa fa-trash-o mail-icon"></i>
                                                    Delete
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="mail-right">
                                    <p>{{reviewData!.review!.createdDate}}</p>
                                </div>
                                <div class="clearfix"> </div>
                                <div id="collapse_{{reviewData!.review!.id}}" class="panel-collapse collapse"
                                    role="tabpanel" aria-labelledby="headingOne">
                                    <div class="mail-body">
                                        <p>{{reviewData!.review!.review}}</p>
                                        <form>
                                            <textarea class="ts-lenos" id="replyInput" name="reply"
                                                placeholder="Reply to sender" required=""></textarea>
                                            <input type="button" class="btn" value="Send"
                                                (click)="reply($event,reviewData)">
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="reviews==null && !isLoading">
                            <div class="errorWrapper">
                                No Review Available
                            </div>
                        </ng-container>
                    </div>
                </div>
                <app-pagination *ngIf="reviews!=null" [self]="this"
                    [change]="changePage" [currentPage]="this.currentPage" [totalPages]="this.totalPages">
                </app-pagination>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>

export enum Status {
  AC = 'AC',
  IA = 'IA',
  PV = 'PV',
  PA = 'PA',
  ER = 'ER',
  RJ = 'RJ',
  PC = 'PC',
  DP = 'DP',
  PP = 'PP',
  DV = 'DV',
}

export const environment = {
  production: false,
  appHost: 'https://business.lenos.com.ng',
  endPoint: 'https://service.phopis.com/lenos',
  tokenBaseEndpoint: 'https://service.phopis.com/lenos/oauth/token',
  googleClientId:"976377509430-tpou8clvmkhfr2fqsrrnof9qce51nonb.apps.googleusercontent.com",
  facebookAppId:"319321306084195",
  clientId: "web-client",
  clientSecret: "password",
  grantType: "password",
  allowedRoutes: [
    '/business/login',
    '/business/signup',
    '/business/verify-account'
  ],
  pageSize: 12,
  appURL: '',
};

<h1 mat-dialog-title>{{title}}
    <span class="material-icons close-icon" (click)="closeDialog()">
        cancel_presentation
    </span>
</h1>
<div mat-dialog-content>
    <form name="form" enctype="multipart/form-data">
        <form name="form">
            <div class="row">
                <div class="sign-u col-md-6">
                    <div class="sign-up1">
                        <h4>Business Name <strong class="star">*</strong> :</h4>
                    </div>
                    <div class="sign-up2">
                        <input type="text" [value]="authenticatedUser.name" class="tf-lenos" name="name" required="true"
                            (keyup)="form.validate($event)">
                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="sign-u col-md-6">
                    <div class="sign-up1">
                        <h4>Email Address <strong class="star">*</strong> :</h4>
                    </div>
                    <div class="sign-up2">
                        <input type="email" [value]="authenticatedUser.email" class="tf-lenos" name="email"
                            required="true" (keyup)="form.validate($event)">
                    </div>
                    <div class="clearfix"> </div>
                </div>
            </div>

            <div class="row">
                <div class="sign-u col-md-6">
                    <div class="sign-up1">
                        <h4>Country <strong class="star">*</strong> :</h4>
                    </div>
                    <div class="sign-up2">
                        <input type="text" class="tf-lenos" name="country" value="Nigeria" disabled="true"
                            readonly="true" required="true" (keyup)="form.validate($event)">
                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="sign-u col-md-6">
                    <div class="sign-up1">
                        <h4>Mobile Number <strong class="star">*</strong> :</h4>
                    </div>
                    <div class="sign-up2">
                        <input type="tel" [value]="authenticatedUser.mobile" class="tf-lenos" name="mobile"
                            maxlength="11" required="true" (keyup)="form.validate($event)">
                    </div>
                    <div class="clearfix"> </div>
                </div>
            </div>
            <div class="row">

                <div class="sign-u col-md-6">
                    <div class="sign-up1">
                        <h4>City <strong class="star">*</strong> :</h4>
                    </div>
                    <div class="sign-up2">
                        <select class="tf-lenos" required="true" name="city" (change)="form.validate($event)">
                            <option selected>{{authenticatedUser.city}}</option>
                            <option value="Kano">Kano</option>
                            <option value="Abuja">Abuja</option>
                            <option value="Rivers">Rivers</option>
                            <option value="Adamawa">Adamawa</option>
                            <option value="Bauchi">Bauchi</option>
                            <option value="Borno">Borno</option>
                            <option value="Kaduna">Kaduna</option>
                            <option value="Katsina">Katsina</option>
                            <option value="Niger">Niger</option>
                            <option value="Plateau">Plateau</option>
                            <option value="Sokoto">Sokoto</option>
                            <option value="Jigawa">Jigawa</option>
                            <option value="Taraba">Taraba</option>
                            <option value="Kebbi">Kebbi</option>
                            <option value="Gombe">Gombe</option>
                            <option value="Kebbi">Kebbi</option>
                            <option value="Yobe">Yobe</option>
                            <option value="Zamfara">Zamfara</option>
                            <option value="Benue">Benue</option>
                            <option value="Nasarawa">Nasarawa</option>
                            <option value="Kogi">Kogi</option>
                            <option value="Umuahia">Umuahia</option>
                            <option value="Nnewe">Nnewe</option>
                            <option value="Ebonyi">Ebonyi</option>
                            <option value="Enugu">Enugu</option>
                            <option value="Ogun">Ogun</option>
                            <option value="Osun">Osun</option>
                            <option value="Oyo">Oyo</option>
                            <option value="Akwa Ibom">Akwa Ibom</option>
                            <option value="Anambra">Anambra</option>
                            <option value="Bayelsa">Bayelsa</option>
                            <option value="Cross Rivers">Cross Rivers</option>
                            <option value="Delta">Delta</option>
                            <option value="Edo">Edo</option>
                            <option value="Ekiti">Ekiti</option>
                            <option value="Imo">Imo</option>
                            <option value="Kwara">Kwara</option>
                            <option value="Ondo">Ondo</option>
                            <option value="Rivers">Rivers</option>
                            <option value="Abia">Abia</option>
                            <option value="Lagos">Lagos</option>
                        </select>
                    </div>
                </div>
                <div class="sign-u col-md-6">
                    <div class="sign-up1">
                        <h4>RC Number <strong class="star">*</strong> :</h4>
                    </div>
                    <div class="sign-up2">
                        <input type="tel" [value]="authenticatedUser.rcNumber!=null?authenticatedUser.rcNumber:''" class="tf-lenos" name="rcNumber"
                            maxlength="11" required="true" (keyup)="form.validate($event)">
                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="sign-u col-md-12">
                    <div class="sign-up1">
                        <h4>Shop Address <strong class="star">*</strong> :</h4>
                    </div>
                    <div class="sign-up2 textarea-large">
                        <textarea class="tf-lenos" nam="address" name="address" required="true"
                            (keyup)="form.validate($event)">{{authenticatedUser.address}}</textarea>
                    </div>
                </div>
            </div>

        </form>
        <div class="group-button dialog-buttons" mat-dialog-actions>
            <div class="sub_home text-right">
                <button type="button" mat-button (click)="updateProfile()" class="btn btn-lenos btn-round">Save</button>
                <div class="clearfix"> </div>
            </div>
        </div>
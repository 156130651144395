<app-sidebar></app-sidebar>
<app-header></app-header>
<div class="main-content">
    <div id="page-wrapper">
        <div class="main-page">
            <div class="row-one">
                <div class="col-md-4 widget">
                    <div class="stats-left ">
                        <h5>Last Minute</h5>
                        <h4>Sales</h4>
                    </div>
                    <div class="stats-right">
                        <label>{{this.sales}}</label>
                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="col-md-4 widget states-mdl">
                    <div class="stats-left">
                        <h5>Last Minute</h5>
                        <h4>Visitors</h4>
                    </div>
                    <div class="stats-right">
                        <label>{{this.visitors}}</label>
                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="col-md-4 widget states-last">
                    <div class="stats-left">
                        <h5>Last Minute</h5>
                        <h4>Delivered</h4>
                    </div>
                    <div class="stats-right">
                        <label>{{this.products}}</label>
                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="clearfix"> </div>
            </div>
            <div class="charts">
                <div class="col-md-4 charts-grids widget">
                    <h4 class="title">Total Sales</h4>
                    <canvas id="bar" height="300" width="400"> </canvas>
                </div>
                <div class="col-md-4 charts-grids widget states-mdl">
                    <h4 class="title">Total Visitors</h4>
                    <canvas id="line" height="300" width="400"> </canvas>
                </div>
                <div class="col-md-4 charts-grids widget">
                    <h4 class="title">Total Ratings</h4>
                    <canvas id="pie" height="300" width="400"> </canvas>
                </div>
                <div class="clearfix"> </div>
            </div>
            <div class="row">
                <div class="col-md-8 map widget-shadow">
                    <h4 class="title">Visitors Map </h4>
                    <div class="map_container">
                        <div id="vmap" style="width: 100%; height: 354px;"></div>
                    </div>
                    </div>
                <div class="col-md-4 social-media widget-shadow">
                    <div class="wid-social twitter">
                        <div class="social-icon">
                            <i class="fa fa-twitter text-light icon-xlg "></i>
                        </div>
                        <div class="social-info">
                            <h3 class="number_counter bold count text-light start_timer counted">3.1 K</h3>
                            <h4 class="counttype text-light">Tweets</h4>
                        </div>
                    </div>
                    <div class="wid-social google-plus">
                        <div class="social-icon">
                            <i class="fa fa-google-plus text-light icon-xlg "></i>
                        </div>
                        <div class="social-info">
                            <h3 class="number_counter bold count text-light start_timer counted">523</h3>
                            <h4 class="counttype text-light">Circles</h4>
                        </div>
                    </div>
                    <div class="wid-social facebook">
                        <div class="social-icon">
                            <i class="fa fa-facebook text-light icon-xlg "></i>
                        </div>
                        <div class="social-info">
                            <h3 class="number_counter bold count text-light start_timer counted">1.06K</h3>
                            <h4 class="counttype text-light">Likes</h4>
                        </div>
                    </div>
                    <div class="wid-social dribbble">
                        <div class="social-icon">
                            <i class="fa fa-dribbble text-light icon-xlg "></i>
                        </div>
                        <div class="social-info">
                            <h3 class="number_counter bold count text-light start_timer counted">1.6 K</h3>
                            <h4 class="counttype text-light">Subscribers</h4>
                        </div>
                    </div>
                    <div class="wid-social vimeo">
                        <div class="social-icon">
                            <i class="fa fa-vimeo-square text-light icon-xlg"> </i>
                        </div>
                        <div class="social-info">
                            <h3 class="number_counter bold count text-light start_timer counted">2.1 m</h3>
                            <h4 class="counttype text-light">Contacts</h4>
                        </div>
                    </div>
                    <div class="wid-social xing">
                        <div class="social-icon">
                            <i class="fa fa-xing text-light icon-xlg "></i>
                        </div>
                        <div class="social-info">
                            <h3 class="number_counter bold count text-light start_timer counted">2525</h3>
                            <h4 class="counttype text-light">Connections</h4>
                        </div>
                    </div>
                    <div class="wid-social flickr">
                        <div class="social-icon">
                            <i class="fa fa-android text-light icon-xlg"></i>
                        </div>
                        <div class="social-info">
                            <h3 class="number_counter bold count text-light start_timer counted">1221</h3>
                            <h4 class="counttype text-light">Media</h4>
                        </div>
                    </div>
                    <div class="wid-social yahoo">
                        <div class="social-icon">
                            <i class="fa fa-yahoo text-light icon-xlg"> Y!</i>
                        </div>
                        <div class="social-info">
                            <h3 class="number_counter bold count text-light start_timer counted">2525</h3>
                            <h4 class="counttype text-light">Connections</h4>
                        </div>
                    </div>
                    <div class="wid-social rss">
                        <div class="social-icon">
                            <i class="fa fa-rss text-light icon-xlg"></i>
                        </div>
                        <div class="social-info">
                            <h3 class="number_counter bold count text-light start_timer counted">1523</h3>
                            <h4 class="counttype text-light">Subscribers</h4>
                        </div>
                    </div>
                    <div class="wid-social youtube">
                        <div class="social-icon">
                            <i class="fa fa-youtube text-light icon-xlg"></i>
                        </div>
                        <div class="social-info">
                            <h3 class="number_counter bold count text-light start_timer counted">1523</h3>
                            <h4 class="counttype text-light">Subscribers</h4>
                        </div>
                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="clearfix"> </div>
            </div>
            <div class="row calender widget-shadow">
                <h4 class="title">Calender</h4>
                <div class="cal1">

                </div>
            </div>
            <div class="clearfix"> </div>
        </div>
    </div>
    <app-footer></app-footer>
<app-sidebar></app-sidebar>
<app-header></app-header>
<div class="main-content">
    <div id="page-wrapper">
        <div class="main-page">
            <div class="login-top left">
                <h3>All Cities »</h3>
            </div>
            <div class="tables">
                <div class="bs-example widget-shadow" data-example-id="hoverable-table">
                    <ng-container *ngIf="isLoading">
                        <app-loader></app-loader>
                    </ng-container>
                    <ng-container *ngIf="citiesOrderAnalytic!=null">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>City</th>
                                    <th>Number of Order</th>
                                    <th>#</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let analytic of citiesOrderAnalytic; let idx=index">
                                    <th scope="row">{{idx}}</th>
                                    <td>{{analytic[0]}}</td>
                                    <td>{{analytic[2]}}</td>
                                    <td>
                                        <div class="controls row">
                                            <div class="col-md-6">
                                                <button type="button" class="btn btn-dynamic btn-round native" [routerLink]="['/product/orders',analytic[1]]">
                                                    View Orders
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                    <ng-container *ngIf="citiesOrderAnalytic==null !isLoading">
                        <div class="errorWrapper">
                            No Location Available
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
